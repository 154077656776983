import React from "react"

import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import Divider from "@material-ui/core/Divider"
import Paper from "@material-ui/core/Paper"
import Typography from "@material-ui/core/Typography"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404 - Page Not found" />
    <Paper>
      <Box m={8} p={4}>
        <Box
          mt={8}
          mb={4}
          textAlign="center"
          fontWeight="fontWeightBold"
          fontSize="h1.fontSize"
        >
          404
        </Box>
        <Box m={4} textAlign="center">
          <Typography variant="body1">
            Sorry. The page requested was not found
          </Typography>
        </Box>
        <Box>
          <Divider variant="middle" />
        </Box>
        <Box m={4} textAlign="center">
          <Button color="secondary" variant="outlined" href="/">
            Go to Home
          </Button>
        </Box>
      </Box>
    </Paper>
  </Layout>
)

export default NotFoundPage
